import {
  AntDesignOutlined,
  AppstoreOutlined,
  BookOutlined,
  CameraOutlined,
  CarOutlined,
  DashboardOutlined,
  DingtalkOutlined,
  EnvironmentOutlined,
  HighlightOutlined,
  HomeOutlined,
  LikeOutlined,
  LockOutlined,
  MedicineBoxOutlined,
  MessageOutlined,
  DollarCircleOutlined,
  PlusCircleOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
  ShakeOutlined,
  ShopOutlined,
  SkinOutlined,
  SolutionOutlined,
  StarOutlined,
  SwapOutlined,
  SwitcherOutlined,
  TagsOutlined,
  ThunderboltOutlined,
  UserOutlined,
  KeyOutlined,
  FileImageFilled,
  WechatOutlined,
  UpCircleOutlined,
  ToolOutlined ,
  PhoneOutlined,
  VideoCameraOutlined ,
  TranslationOutlined ,
  QuestionOutlined ,
  SettingOutlined ,
  FundOutlined
 } from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { GiPodiumWinner } from "react-icons/gi";
import style from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark";


const LIST_ORDERS = "ListOrders";
const LIST_REQUESTS = "ListRequests";
const GENERIC_OPTIONS = "GenericOptions";

const socialNavTree = [
  {
    key: "app-social",
    path: `${APP_PREFIX_PATH}/apps/social`,
    title: "sidenav.social",
    icon: PlusCircleOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "app-Reviews",
        path: `${APP_PREFIX_PATH}/apps/social/reviews`,
        title: "sidenav.social.reviews",
        icon: AntDesignOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "app-Recommended",
        path: `${APP_PREFIX_PATH}/apps/social/recommended`,
        title: "sidenav.social.recommended",
        icon: LikeOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];
const geographiTree = [
  {
    key: "app-geographic",
    path: `${APP_PREFIX_PATH}/apps/geographic`,
    title: "sidenav.geographic",
    icon: PlusCircleOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "app-Country",
        path: `${APP_PREFIX_PATH}/apps/geographic/countries`,
        title: "sidenav.geographic.country",
        icon: EnvironmentOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "app-City",
        path: `${APP_PREFIX_PATH}/apps/geographic/cities`,
        title: "sidenav.geographic.city",
        icon: EnvironmentOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];
const genericTree = [
  {
    key: "app-generic",
    path: `${APP_PREFIX_PATH}/apps/generic`,
    title: "sidenav.generic",
    icon: PlusCircleOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "app-generic",
        path: `${APP_PREFIX_PATH}/apps/generic/tags`,
        title: "sidenav.generic.tags",
        icon: ShakeOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "app-generic-Detalis",
        path: `${APP_PREFIX_PATH}/apps/generic/detalies`,
        title: "sidenav.generic.detalies",
        icon: SwitcherOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "app-generic-Genericdetalies",
            path: `${APP_PREFIX_PATH}/apps/generic/detalies`,
            title: "sidenav.generic.genericDetalies",
            // icon: ShakeOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "app-generic-detailsType",
            path: `${APP_PREFIX_PATH}/apps/generic/detaliesType`,
            title: "sidenav.generic.detailesType",
            // icon: ShakeOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "app-generic-incressProfit",
        path: `${APP_PREFIX_PATH}/apps/generic/increaseProfit`,
        title: "sidenav.generic.incressProfit",
        icon: DollarCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: "app-City",
      //   path: `${APP_PREFIX_PATH}/apps/generic/cities`,
      //   title: "sidenav.generic.city",
      //   icon: EnvironmentOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
    ],
  },
];

const dashBoardNavTree = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "sidenav.dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboards-default",
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        title: "sidenav.dashboard.default",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-Statics",
        path: `${APP_PREFIX_PATH}/dashboards/Statics`,
        title: "Statics",
        icon: FundOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

// CATIGORIES

const appsNavTree = [
  {
    key: "apps",
    path: `${APP_PREFIX_PATH}/apps`,
    title: "sidenav.apps",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "apps-dress",
        path: `${APP_PREFIX_PATH}/apps`,
        title: "sidenav.apps.dresses",
        icon: SkinOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "apps-1-dressStore",
            path: `${APP_PREFIX_PATH}/apps/dresses/dressStore`,
            title: "sidenav.apps.dresses.dressStore",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-dress-dresses",
            path: `${APP_PREFIX_PATH}/apps/dresses/dress`,
            title: "sidenav.apps.dresses.dresses",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          //   key: "apps-dress-dressVariation",
          //   path: `${APP_PREFIX_PATH}/apps/dresses/dressVariation`,
          //   title: "sidenav.apps.dresses.dressVariation",
          //   icon: "",
          //   breadcrumb: false,
          //   submenu: [],
          // },
          // {
          //   key: "apps-dress-dressVariationPayment",
          //   path: `${APP_PREFIX_PATH}/apps/dresses/dressVariationPayment`,
          //   title: "sidenav.apps.dresses.dressVariationPayment",
          //   icon: "",
          //   breadcrumb: false,
          //   submenu: [],
          // },

          {
            key: "apps-dress-request",
            path: `${APP_PREFIX_PATH}/apps/dresses/${LIST_REQUESTS}`,
            title: "sidenav.apps.dresses.request",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-dress-orders",
            path: `${APP_PREFIX_PATH}/apps/dresses/${LIST_ORDERS}`,
            title: "sidenav.apps.dresses.orders",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-dress-unApproved",
            path: `${APP_PREFIX_PATH}/apps/dresses/unApproved`,
            title: "sidenav.apps.dresses.unApproved",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },

          {
            key: "apps-dress-tags",
            path: `${APP_PREFIX_PATH}/apps/dresses/dressTagss`,
            title: "sidenav.apps.dresses.dressTag",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-dress-details",
            path: `${APP_PREFIX_PATH}/apps/dresses/dressDetails`,
            title: "sidenav.apps.dresses.details",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-dress-offline",
            path: `${APP_PREFIX_PATH}/apps/dresses/offline`,
            title: "sidenav.apps.dresses.offline",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-dress-Recycle Bin",
            path: `${APP_PREFIX_PATH}/apps/dresses/trash`,
            title: "Recycle Bin",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "apps-cars",
        path: `${APP_PREFIX_PATH}/apps`,
        title: "sidenav.apps.car",
        icon: CarOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "apps-car-cars-agency",
            path: `${APP_PREFIX_PATH}/apps/cars`,
            title: "sidenav.apps.car.agency",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-car-cars",
            path: `${APP_PREFIX_PATH}/apps/cars/cars`,
            title: "sidenav.apps.car.cars",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },

          // {
          //   key: "apps-car-carReservationOptions",
          //   path: `${APP_PREFIX_PATH}/apps/cars/carReservationOptions`,
          //   title: "sidenav.apps.car.carReservationOptions",
          //   icon: "",
          //   breadcrumb: false,
          //   submenu: [],
          // },
          // {
          //   key: "apps-car-orders",
          //   path: `${APP_PREFIX_PATH}/apps/cars/CarOption`,
          //   title: "sidenav.apps.car.orders",
          //   icon: "",
          //   breadcrumb: false,
          //   submenu: [],
          // },
          {
            key: "apps-car-carTags",
            path: `${APP_PREFIX_PATH}/apps/cars/carTags`,
            title: "sidenav.apps.car.carTags",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-car-details",
            path: `${APP_PREFIX_PATH}/apps/cars/carDetails`,
            title: "sidenav.apps.car.details",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-car-request",
            path: `${APP_PREFIX_PATH}/apps/cars/${LIST_REQUESTS}`,
            title: "sidenav.apps.car.request",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-car-orders",
            path: `${APP_PREFIX_PATH}/apps/cars/${LIST_ORDERS}`,
            title: "sidenav.apps.car.orders",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },

          {
            key: "apps-car-unApproved",
            path: `${APP_PREFIX_PATH}/apps/cars/unApproved`,
            title: "sidenav.apps.car.unApproved",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-cars-offline",
            path: `${APP_PREFIX_PATH}/apps/cars/offline`,
            title: "sidenav.apps.cars.offline",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-cars-Recycle Bin",
            path: `${APP_PREFIX_PATH}/apps/cars/trash`,
            title: "Recycle Bin",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "apps-hotels",
        path: `${APP_PREFIX_PATH}/apps`,
        title: "sidenav.apps.hotels",
        icon: HomeOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "apps-hotel-hotels",
            path: `${APP_PREFIX_PATH}/apps/hotel`,
            title: "sidenav.apps.hotels.hotels",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-hotel-hotels-wedding",
            path: `${APP_PREFIX_PATH}/apps/hotel/weddingHall`,
            title: "sidenav.apps.hotels.wedding",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },

          {
            key: "apps-hotel-request",
            path: `${APP_PREFIX_PATH}/apps/hotel/${LIST_REQUESTS}`,
            title: "sidenav.apps.hotels.request",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          //Periods
          {
            key: "apps-hotel-Periods",
            path: `${APP_PREFIX_PATH}/apps/hotel/ListPeriods`,
            title: "Periods",
            icon: "",
            breadcrumb: false,
            submenu: [],
            type:""
          },
           //Special Date
           {
            key: "apps-hotel-ListSpecial_date",
            path: `${APP_PREFIX_PATH}/apps/hotel/ListSpecial_date`,
            title: "Special Date",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-hotel-orders",
            path: `${APP_PREFIX_PATH}/apps/hotel/${LIST_ORDERS}`,
            title: "sidenav.apps.hotels.orders",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-hotel-extraPackage",
            path: `${APP_PREFIX_PATH}/apps/hotel/extraPackage`,
            title: "sidenav.apps.hotels.extraPackage",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-hotel-unApproved",
            path: `${APP_PREFIX_PATH}/apps/hotel/unApproved`,
            title: "sidenav.apps.hotels.unApproved",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },

          {
            key: "apps-hotel-hotelTagss",
            path: `${APP_PREFIX_PATH}/apps/hotel/hotelTagss`,
            title: "sidenav.apps.hotel.hotelTagss",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-hotel-details",
            path: `${APP_PREFIX_PATH}/apps/hotel/hotelDetails`,
            title: "sidenav.apps.hotel.details",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-hotel-offline",
            path: `${APP_PREFIX_PATH}/apps/hotel/offline`,
            title: "sidenav.apps.hotel.offline",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-hotel-Recycle Bin",
            path: `${APP_PREFIX_PATH}/apps/hotel/trash`,
            title: "Recycle Bin",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          
        ],
      },
      {
        key: "apps-doctors",
        path: `${APP_PREFIX_PATH}/apps`,
        title: "sidenav.apps.doctors",
        icon: MedicineBoxOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "apps-doctor-clinics",
            path: `${APP_PREFIX_PATH}/apps/doctors`,
            title: "sidenav.apps.doctors.clinics",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-doctor-checkup",
            path: `${APP_PREFIX_PATH}/apps/doctors/checkup`,
            title: "sidenav.apps.doctors.checkup",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          // {
          //   key: "apps-doctor-avaliabilty",
          //   path: `${APP_PREFIX_PATH}/apps/doctors/avaliabilty`,
          //   title: "sidenav.apps.doctors.avaliabilty",
          //   icon: "",
          //   breadcrumb: false,
          //   submenu: [],
          // },
          // {
          //   key: "apps-doctor-checkupOptions",
          //   path: `${APP_PREFIX_PATH}/apps/doctors/checkupOptions`,
          //   title: "sidenav.apps.doctors.checkupOptions",
          //   icon: "",
          //   breadcrumb: false,
          //   submenu: [],
          // },

          {
            key: "apps-doctor-checkupTag",
            path: `${APP_PREFIX_PATH}/apps/doctors/checkupTag`,
            title: "sidenav.apps.doctors.checkupTag",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-doctor-details",
            path: `${APP_PREFIX_PATH}/apps/doctors/doctorDetails`,
            title: "sidenav.apps.doctors.details",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-doctor-request",
            path: `${APP_PREFIX_PATH}/apps/doctors/${LIST_REQUESTS}`,
            title: "sidenav.apps.doctors.request",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          // {
          //   key: "apps-doctor-checkupReservationOptions",
          //   path: `${APP_PREFIX_PATH}/apps/doctors/checkupReservationOptions`,
          //   title: "sidenav.apps.doctors.checkupReservationOptions",
          //   icon: "",
          //   breadcrumb: false,
          //   submenu: [],
          // },
          {
            key: "apps-doctor-orders",
            path: `${APP_PREFIX_PATH}/apps/doctors/${LIST_ORDERS}`,
            title: "sidenav.apps.doctors.orders",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-doctors-unApproved",
            path: `${APP_PREFIX_PATH}/apps/doctors/unApproved`,
            title: "sidenav.apps.doctors.unApproved",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-doctor-offline",
            path: `${APP_PREFIX_PATH}/apps/doctors/offline`,
            title: "sidenav.apps.doctor.offline",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-doctor-Recycle Bin",
            path: `${APP_PREFIX_PATH}/apps/doctors/trash`,
            title: "Recycle Bin",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },

      {
        key: "apps-makeup",
        path: `${APP_PREFIX_PATH}/apps`,
        title: "sidenav.apps.makeup",
        icon: HighlightOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "apps-makeup-makeup",
            path: `${APP_PREFIX_PATH}/apps/makeup`,
            title: "sidenav.apps.makeup.makeup",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-makeup-makeupService",
            path: `${APP_PREFIX_PATH}/apps/makeup/makeupService`,
            title: "sidenav.apps.makeup.makeupService",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          // {
          //   key: "apps-makeup-makeupServiceOptions",
          //   path: `${APP_PREFIX_PATH}/apps/makeup/makeupServiceOptions`,
          //   title: "sidenav.apps.makeup.makeupServiceOptions",
          //   icon: "",
          //   breadcrumb: false,
          //   submenu: [],
          // },
          // {
          //   key: "apps-makeup-checkupReservationOptions",
          //   path: `${APP_PREFIX_PATH}/apps/makeup/makeupReservationOptions`,
          //   title: "sidenav.apps.makeup.makeupReservationOptions",
          //   icon: "",
          //   breadcrumb: false,
          //   submenu: [],
          // },

          {
            key: "apps-makeup-makeupTags",
            path: `${APP_PREFIX_PATH}/apps/makeup/makeupTags`,
            title: "sidenav.apps.makeup.makeupTags",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-makeup-details",
            path: `${APP_PREFIX_PATH}/apps/makeup/makeupDetails`,
            title: "sidenav.apps.makeup.details",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-makeup-request",
            path: `${APP_PREFIX_PATH}/apps/makeup/${LIST_REQUESTS}`,
            title: "sidenav.apps.makeup.request",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },

          {
            key: "apps-makeup-orders",
            path: `${APP_PREFIX_PATH}/apps/makeup/${LIST_ORDERS}`,
            title: "sidenav.apps.makeup.orders",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-makeup-unApproved",
            path: `${APP_PREFIX_PATH}/apps/makeup/unApproved`,
            title: "sidenav.apps.makeup.unApproved",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-makeup-offline",
            path: `${APP_PREFIX_PATH}/apps/makeup/offline`,
            title: "sidenav.apps.makeup.offline",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-makeup-Recycle Bin",
            path: `${APP_PREFIX_PATH}/apps/makeup/trash`,
            title: "Recycle Bin",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "apps-Beauty-Center",
        path: `${APP_PREFIX_PATH}/apps/beauty`,
        title: "sidenav.apps.beauty",
        icon: StarOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "apps-beauty-beauty",
            path: `${APP_PREFIX_PATH}/apps/beauty`,
            title: "sidenav.apps.beauty.beauty",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },

          {
            key: "apps-beauty-beautyCenterServices",
            path: `${APP_PREFIX_PATH}/apps/beauty/beautyCenterServices`,
            title: "sidenav.apps.beauty.beautyCenterServices",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          // {
          //   key: "apps-beauty-beautyCenterServiceOptions",
          //   path: `${APP_PREFIX_PATH}/apps/beauty/beautyCenterServiceOptions`,
          //   title: "sidenav.apps.beauty.beautyCenterServiceOptions",
          //   icon: "",
          //   breadcrumb: false,
          //   submenu: [],
          // },
          // {
          //   key: "apps-beauty-beautyCenterServiceReservationOptions",
          //   path: `${APP_PREFIX_PATH}/apps/beauty/beautyCenterServiceReservationOptions`,
          //   title: "sidenav.apps.beauty.beautyCenterServiceReservationOptions",
          //   icon: "",
          //   breadcrumb: false,
          //   submenu: [],
          // },
          {
            key: "apps-beauty-beautyCenterTag",
            path: `${APP_PREFIX_PATH}/apps/beauty/beautyCenterTag`,
            title: "sidenav.apps.beauty.beautyCenterTag",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-beauty-details",
            path: `${APP_PREFIX_PATH}/apps/beauty/beautyDetails`,
            title: "sidenav.apps.beauty.details",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-beauty-request",
            path: `${APP_PREFIX_PATH}/apps/beauty/${LIST_REQUESTS}`,
            title: "sidenav.apps.beauty.request",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-beauty-orders",
            path: `${APP_PREFIX_PATH}/apps/beauty/${LIST_ORDERS}`,
            title: "sidenav.apps.beauty.orders",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-beauty-unApproved",
            path: `${APP_PREFIX_PATH}/apps/beauty/unApproved`,
            title: "sidenav.apps.beauty.unApproved",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-beauty-offline",
            path: `${APP_PREFIX_PATH}/apps/beauty/offline`,
            title: "sidenav.apps.beauty.offline",
            icon: "",
            breadcrumb: false,
            submenu: [],
            
          },
          {
            key: "apps-beauty-Recycle Bin",
            path: `${APP_PREFIX_PATH}/apps/beauty/trash`,
            title: "Recycle Bin",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "apps-Photographer",
        path: `${APP_PREFIX_PATH}/apps/photoGrapher`,
        title: "sidenav.apps.photographer",
        icon: CameraOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "apps-photo-photoGrapher",
            path: `${APP_PREFIX_PATH}/apps/photographer/photoGrapher`,
            title: "sidenav.apps.photographer.photoGrapher",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-photo-photoSession",
            path: `${APP_PREFIX_PATH}/apps/photographer/photoSession`,
            title: "sidenav.apps.photographer.photoSession",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          // {
          //   key: "apps-photo-photoSessionOptions",
          //   path: `${APP_PREFIX_PATH}/apps/photographer/photoSessionOptions`,
          //   title: "sidenav.apps.photographer.photoSessionOptions",
          //   icon: "",
          //   breadcrumb: false,
          //   submenu: [],
          // },
          // {
          //   key: "apps-photo-photoSessionReservationOptions",
          //   path: `${APP_PREFIX_PATH}/apps/photographer/photoSessionReservationOptions`,
          //   title: "sidenav.apps.photographer.photoSessionReservationOptions",
          //   icon: "",
          //   breadcrumb: false,
          //   submenu: [],
          // },
          {
            key: "apps-photo-photoSessionTags",
            path: `${APP_PREFIX_PATH}/apps/photographer/photoSessionTags`,
            title: "sidenav.apps.photographer.photoSessionTags",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-photo-details",
            path: `${APP_PREFIX_PATH}/apps/photographer/photoDetails`,
            title: "sidenav.apps.photographer.details",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-photo-request",
            path: `${APP_PREFIX_PATH}/apps/photographer/${LIST_REQUESTS}`,
            title: "sidenav.apps.photographer.request",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-photo-orders",
            path: `${APP_PREFIX_PATH}/apps/photographer/${LIST_ORDERS}`,
            title: "sidenav.apps.photographer.orders",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-photographer-unApproved",
            path: `${APP_PREFIX_PATH}/apps/photographer/unApproved`,
            title: "sidenav.apps.photographer.unApproved",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-photo-offline",
            path: `${APP_PREFIX_PATH}/apps/photographer/offline`,
            title: "sidenav.apps.photographer.offline",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-photo-Recycle Bin",
            path: `${APP_PREFIX_PATH}/apps/photographer/trash`,
            title: "Recycle Bin",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "apps-Trips",
        path: `${APP_PREFIX_PATH}/apps/trips`,
        title: "sidenav.apps.trips",
        icon: DingtalkOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "apps-trips-travelAgencies",
            path: `${APP_PREFIX_PATH}/apps/trips/travelAgency`,
            title: "sidenav.apps.trips.agency",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-trips-trips",
            path: `${APP_PREFIX_PATH}/apps/trips`,
            title: "sidenav.apps.trips.trips",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },

          // {
          //   key: "apps-trips-tripsOptions",
          //   path: `${APP_PREFIX_PATH}/apps/trips/tripsOptions`,
          //   title: "sidenav.apps.trips.tripsOptions",
          //   icon: "",
          //   breadcrumb: false,
          //   submenu: [],
          // },
          {
            key: "apps-trips-tripsTags",
            path: `${APP_PREFIX_PATH}/apps/trips/tripsTags`,
            title: "sidenav.apps.trips.tripsTags",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-trips-details",
            path: `${APP_PREFIX_PATH}/apps/trips/tripsDetails`,
            title: "sidenav.apps.trips.details",
            icon: "",

            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-trips-destination",
            path: `${APP_PREFIX_PATH}/apps/trips/destination`,
            title: "sidenav.apps.trips.destination",
            icon: "",

            breadcrumb: false,
            submenu: [],
          },
          // {
          //   key: "apps-trips-reservationOption",
          //   path: `${APP_PREFIX_PATH}/apps/trips/tripsReservationOptions`,
          //   title: "sidenav.apps.trips.reservationOption",
          //   icon: "",
          //   breadcrumb: false,
          //   submenu: [],
          // },
          {
            key: "apps-trips-request",
            path: `${APP_PREFIX_PATH}/apps/trips/${LIST_REQUESTS}`,
            title: "sidenav.apps.trips.request",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-trips-orders",
            path: `${APP_PREFIX_PATH}/apps/trips/${LIST_ORDERS}`,
            title: "sidenav.apps.trips.orders",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-trips-unApproved",
            path: `${APP_PREFIX_PATH}/apps/trips/unApproved`,
            title: "sidenav.apps.trips.unApproved",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-trips-offline",
            path: `${APP_PREFIX_PATH}/apps/trips/offline`,
            title: "sidenav.apps.trips.offline",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];
//reservaions
const Reservations = [
  {
    key: "apps",
    path: `${APP_PREFIX_PATH}/apps/Reservations`,
    title: "reservations",
    icon: PlusCircleOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "app-ListofReservations",
        path: `${APP_PREFIX_PATH}/apps/Reservations/ListofReservations`,
        title: "List Of Reservations",
        icon: ShakeOutlined,
        breadcrumb: false,
        submenu: [],
      },

   
    ],
  },
];
//True View
const True_View = [
  {
    key: "apps",
    path: `${APP_PREFIX_PATH}/apps/True_View`,
    title: "True View",
    icon: PlusCircleOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "app-ListofTrue_View",
        path: `${APP_PREFIX_PATH}/apps/True_View/ListofTrue_View`,
        title: "List Of True Views",
        icon: VideoCameraOutlined,
        breadcrumb: false,
        submenu: [],
      },

   
    ],
  },
];
//Banners
const Banners = [
  {
    key: "apps",
    path: `${APP_PREFIX_PATH}/apps/Banners`,
    title: "Banners",
    icon: PlusCircleOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "app-Banners_Media",
        path: `${APP_PREFIX_PATH}/apps/Banners/BannersMedia`,
        title: "Banners-Media",
        icon: FileImageFilled ,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "app-targetType",
        path: `${APP_PREFIX_PATH}/apps/Banners/targetType`,
        title: "Target-Type",
        icon: UpCircleOutlined  ,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "app-Banner",
        path: `${APP_PREFIX_PATH}/apps/Banners/Banner`,
        title: "Banner",
        icon: ToolOutlined  ,
        breadcrumb: false,
        submenu: [],
      },

   
    ],
  },
];

//Banners
const Rules = [
  {
    key: "apps",
    path: `${APP_PREFIX_PATH}/apps/Rules`,
    title: "Admin Rules",
    icon: PlusCircleOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "app-Rules",
        path: `${APP_PREFIX_PATH}/apps/Rules/Roles`, 
        title: "Roles",
        icon: KeyOutlined ,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "app-Permision",
        path: `${APP_PREFIX_PATH}/apps/Rules/Permision`, 
        title: "Permision",
        icon: KeyOutlined ,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "app-Assign_perm",
        path: `${APP_PREFIX_PATH}/apps/Rules/Assign_perm`, 
        title: "Assign_perm",
        icon: KeyOutlined ,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: "app-targetType",
      //   path: `${APP_PREFIX_PATH}/apps/Banners/targetType`,
      //   title: "Target-Type",
      //   icon: UpCircleOutlined  ,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      // {
      //   key: "app-Banner",
      //   path: `${APP_PREFIX_PATH}/apps/Banners/Banner`,
      //   title: "Banner",
      //   icon: ToolOutlined  ,
      //   breadcrumb: false,
      //   submenu: [],
      // },

   
    ],
  },
];

//competitions
const competitions = [
  {
    key: "apps",
    path: `${APP_PREFIX_PATH}/apps/Competitions`,
    title: "Competitions",
    icon: PlusCircleOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "app-Contestants",
        path: `${APP_PREFIX_PATH}/apps/Competitions/Contestants`,
        title: "Contestants",
        icon: GiPodiumWinner,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "app-Competitions",
        path: `${APP_PREFIX_PATH}/apps/Competitions/Competitions`,
        title: "Competitions",
        icon: UpCircleOutlined  ,
        breadcrumb: false,
        submenu: [],
      },
       

   
    ],
  },
];


const componentsNavTree = [
  {
    key: "components",
    path: `${APP_PREFIX_PATH}/apps/CRM`,
    title: "sidenav.support",
    icon: "",
    breadcrumb: true,
    submenu: [
      {
        key: "apps-project-chatroom",
        path: `${APP_PREFIX_PATH}/apps/CRM/chatroom`,
        title: "Chat Room",
        icon: MessageOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "apps-project-SMS",
        path: `${APP_PREFIX_PATH}/apps/CRM/SMS`,
        title: "SMS",
        icon: WechatOutlined,
        breadcrumb: false,
        submenu: [],
      },
      //HOW_TO
      {
        key: "apps-project-HOW_TO",
        path: `${APP_PREFIX_PATH}/apps/CRM/HOW_TO`,
        title: "How TO",
        icon: QuestionOutlined,
        breadcrumb: false,
        submenu: [],
      },
        //Edit_Settings
        {
          key: "apps-project-Edit_Service",
          path: `${APP_PREFIX_PATH}/apps/CRM/ListOFService`,
          title: "Edit Services",
          icon: SettingOutlined,
          breadcrumb: false,
          submenu: [],
        },
      {
        key: "apps-project-list",
        path: `${APP_PREFIX_PATH}/apps/CRM`,
        title: "sidenav.support.complain",
        icon: SolutionOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "apps-chat-support",
        path: `${APP_PREFIX_PATH}/apps/chat`,
        title: "sidenav.support.chat",
        icon: MessageOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "apps-project-clients",
        path: `${APP_PREFIX_PATH}/apps/CRM/client`,
        title: "sidenav.support.client",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "apps-project-clients-online",
            path: `${APP_PREFIX_PATH}/apps/CRM/client`,
            title: "sidenav.apps.clients.onlineClients",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-project-clients-offline",
            path: `${APP_PREFIX_PATH}/apps/CRM/offlineClient`,
            title: "sidenav.apps.clients.offlineClients",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-project-clientRequestCalls",
            path: `${APP_PREFIX_PATH}/apps/CRM/clientRequestCalls`,
            title: "sidenav.support.clientRequestCalls",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-project-OnlineclientsPending",
            path: `${APP_PREFIX_PATH}/apps/CRM/clientPending`,
            title: "Online Client Pending",
            breadcrumb: false,
            submenu: [],
          },
          
        ],
      },
      {
        key: "apps-project-seller",
        path: `${APP_PREFIX_PATH}/apps/CRM/seller`,
        title: "sidenav.support.seller",
        icon: ShopOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "apps-project-sellers",
            path: `${APP_PREFIX_PATH}/apps/CRM/seller`,
            title: "sidenav.support.sellers",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-project-sellerChanges",
            path: `${APP_PREFIX_PATH}/apps/CRM/sellerChanges`,
            title: "sidenav.support.sellerChanges",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-project-requestCalls",
            path: `${APP_PREFIX_PATH}/apps/CRM/requestCalls`,
            title: "sidenav.support.requestCalls",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-project-rejectResons",
            path: `${APP_PREFIX_PATH}/apps/CRM/rejectResons`,
            title: "sidenav.support.rejectResons",
            breadcrumb: false,
            submenu: [],
          },
          // {
          //   key: "apps-project-packageRequest",
          //   path: `${APP_PREFIX_PATH}/apps/CRM/packageRequest`,
          //   title: "sidenav.support.packageRequest",
          //   breadcrumb: false,
          //   submenu: [],
          // },
          // {
          //   key: "apps-project-flashDealRequests",
          //   path: `${APP_PREFIX_PATH}/apps/CRM/FlashDealRequest`,
          //   title: "sidenav.support.flashDealRequest",
          //   breadcrumb: false,
          //   submenu: [],
          // },
          {
            key: "apps-project-becomeSeller",
            path: `${APP_PREFIX_PATH}/apps/CRM/becomeSeller`,
            title: "sidenav.support.becomeSeller",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-project-sellerLogs",
            path: `${APP_PREFIX_PATH}/apps/CRM/sellerLogs`,
            title: "sidenav.support.sellerLogs",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "apps-project-adminlogs",
        path: `${APP_PREFIX_PATH}/apps/CRM/adminlogs`,
        title: "sidenav.support.adminlogs",
        icon: SwapOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "apps-project-Search Keywords",
        path: `${APP_PREFIX_PATH}/apps/CRM/Search_Keywords`,
        title: "Search Keywords",
        icon: KeyOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "apps-project-Search By Phone",
        path: `${APP_PREFIX_PATH}/apps/CRM/Search_By_Phone`,
        title: "Search By Phone",
        icon: PhoneOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "apps-project-Translations",
        path: `${APP_PREFIX_PATH}/apps/CRM/Translations`,
        title: "Translations",
        icon: TranslationOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];
const StaticNavTree = [
  {
    key: "Static",
    path: `${APP_PREFIX_PATH}/apps/staticPages`,
    title: "sidenav.static",
    icon: "",
    breadcrumb: true,
    submenu: [
      {
        key: "apps-project-faq",
        path: `${APP_PREFIX_PATH}/apps/staticPages`,
        title: "sidenav.support.faq",
        icon: QuestionCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "apps-chat-support.privacy",
        path: `${APP_PREFIX_PATH}/apps/staticPages/privacy`,
        title: "sidenav.support.Privacy",
        icon: LockOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "apps-chat-support.terms",
        path: `${APP_PREFIX_PATH}/apps/staticPages/terms`,
        title: "sidenav.support.terms",
        icon: ProfileOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];
const docsNavTree = [
  {
    key: "marketing",
    path: `${APP_PREFIX_PATH}/apps`,
    title: "sidenav.marketing",
    icon: BookOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "docs-packages",
        path: `${APP_PREFIX_PATH}/apps/marketing/packagess`,
        title: "sidenav.marketing.packages",
        icon: AppstoreOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "docs-offers",
        path: `${APP_PREFIX_PATH}/apps/marketing/offers`,
        title: "sidenav.marketing.offers",
        icon: TagsOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "docs-flashdeals",
        path: `${APP_PREFIX_PATH}/apps/marketing/flashdeals`,
        title: "sidenav.marketing.flashdeals",
        icon: ThunderboltOutlined,
        breadcrumb: false,
        submenu: [],
      },
     
    ],
  },
];


const navigationConfig =  
 
  [
    ...dashBoardNavTree,
    ...appsNavTree,
    ...Reservations,
    ...True_View,
    ...Banners,
    ...competitions,
  
    ...genericTree,
    ...componentsNavTree,
    ...StaticNavTree,
    ...Rules,
    // ...extraNavTree,
    ...socialNavTree,
    ...geographiTree,
    ...docsNavTree,
  ]
 
  const this_for_return=()=>{
 
 
   return   [
  // [
    ...dashBoardNavTree,
    ...appsNavTree,
    ...Reservations,
    ...True_View,
    ...Banners,
    ...competitions,
  
    ...genericTree,
    ...componentsNavTree,
    ...StaticNavTree,
    ...Rules,

    // ...extraNavTree,
    ...socialNavTree,
    ...geographiTree,
    ...docsNavTree,
   ]
 
    
  }


export default this_for_return();
